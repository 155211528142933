import { AlertIcon } from "../Icons"
import styles from "./styles.module.scss"

interface IProps {
  text: any
}
const Alert = ({ text }: IProps) => {
  return (
    <div className={styles.alert}>
      <AlertIcon />
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default Alert

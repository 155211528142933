/* eslint-disable camelcase */
import classNames from "classnames"
import { useContext, useEffect, useState } from "react"
import { useRef } from "preact/hooks"
import PaymentPage from "../PaymentPage"

import { API } from "@/common/api"

import usePageContext from "@/common/hooks/usePageState"
import usePaymentStateContext from "@/common/hooks/usePaymentState"
import usePlausible from "@/common/hooks/usePlausible"

import { PlausibleEvent } from "@/common/enums"
import { TransactionAccumulatedResponse } from "@/common/types"
import { formatAmount, getOrderId } from "@/utils"

import Alert from "@/components/atoms/Alert"
import Footer from "@/components/Footer"
import { MoneyIcon, WarningIcon } from "@/components/atoms/Icons"

import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

const AccumulatedPage = ({
  stockpiling_amount,
  lacks_amount,
  minimum_deposit,
  currency_symbol,
}: TransactionAccumulatedResponse) => {
  const panelRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>(0)

  const { changePaymentState } = usePaymentStateContext()
  const { changePage } = usePageContext()
  const { getTranslation } = useContext(LanguageContext)
  const setEvent = usePlausible()

  useEffect(() => {
    setEvent(PlausibleEvent.OpenAccumulatedPage)
  }, [])

  useEffect(() => {
    if (panelRef?.current) {
      const panelWidth = panelRef?.current.clientWidth
      const ratio = panelWidth / +minimum_deposit
      setWidth(Math.floor(+stockpiling_amount * ratio))
    }
  }, [panelRef?.current, stockpiling_amount, minimum_deposit])

  const onClick = () => {
    API.getOrder(getOrderId()).then(resp => {
      changePaymentState(resp.data.data)
      changePage(<PaymentPage {...resp.data.data} />)
    })
    setEvent(PlausibleEvent.ClickToPayNowButton)
  }

  return (
    <>
      <div className={styles.accumulatedPage}>
        <div className={styles.icon}>
          <WarningIcon />
        </div>
        <div className={styles.title}>
          {getTranslation("accumulationHeaderText")}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.panel} ref={panelRef}>
            <div className={styles.panelTitle}>{getTranslation("amountReceived")}</div>
            <div className={styles.panelPaid}>
              <MoneyIcon />
              {formatAmount(+stockpiling_amount)} {currency_symbol}
            </div>
            <Alert text={getTranslation("accumulationFormHint")} />
            <div className={styles.progress}>
              <div className={styles.progressBar}>
                <div
                  style={{ width: `${width}px` }}
                  className={classNames(styles.progressBar, styles.progressBarActual)}
                />
              </div>
              <div className={styles.progresslabels}>
                <div className={classNames(styles.label, styles.labelLeft)}>
                  <div className={styles.labelHeading}>{getTranslation("paid")}</div>
                  <div className={styles.labelText}>
                    {formatAmount(+stockpiling_amount)} {currency_symbol}
                  </div>
                </div>
                <div className={classNames(styles.label, styles.labelRight)}>
                  <div className={styles.labelHeading}>{getTranslation("minDeposit")}</div>
                  <div className={styles.labelText}>
                    {formatAmount(+minimum_deposit)} {currency_symbol}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.panelButton}>
              <button className={styles.button} onClick={onClick}>
                {getTranslation("buttonPay").replace("<lacks_amount>", formatAmount(+lacks_amount)).replace("<currency_symbol>", currency_symbol)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AccumulatedPage

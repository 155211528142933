import classNames from "classnames"
import React, { useContext, useState } from "react"
import QRCode from "react-qr-code"
import { ArrowCircleIcon, QRIcon } from "../Icons"
import { QRScanIcon } from "../QRScanIcon"
import SimpleModal from "../SimpleModal"

import usePlausible from "@/common/hooks/usePlausible"
import useScreenSize, { ScreenSize } from "@/common/hooks/useScreenSize"

import { PlausibleEvent } from "@/common/enums"

import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

export interface QRCodeProps {
  qrLink: string
  content: string
}

export const QRField = ({ qrLink, content }: QRCodeProps) => {
  const setEvent = usePlausible()
  const { getTranslation } = useContext(LanguageContext)

  const [showModal, setShowModal] = useState<boolean>(false)
  const isMobile = useScreenSize() === ScreenSize.MOBILE

  const openUpiApp = () => {
    setEvent(PlausibleEvent.ClickToOpenUpiAppButton)
    window.location.href = qrLink
  }

  const openQR = () => {
    setShowModal(true)
    setEvent(PlausibleEvent.OpenQR)
  }

  const closeQR = () => {
    setShowModal(false)
    setEvent(PlausibleEvent.CloseQR)
  }

  return (
    <>
      {isMobile ? (
        <div className={styles.mobile}>
          <div className={styles.qrBlock}>
            <QRIcon />
            <div>
              <div className={styles.qrBlockText}>
                {getTranslation("textPayQRCode")}
              </div>
              <div className={styles.qrBlockLink} onClick={openQR}>
                {getTranslation("linkClickHere")}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.qrCode}>
              <div
                className={classNames(styles.top, styles.left)}
                style={{ borderRadius: "13px 0 0 0" }}
              />
              <div
                className={classNames(styles.top, styles.right)}
                style={{ borderRadius: "0 13px 0 0" }}
              />
              <div
                className={classNames(styles.bottom, styles.right)}
                style={{ borderRadius: "0 0 13px 0" }}
              />
              <div
                className={classNames(styles.bottom, styles.left)}
                style={{ borderRadius: "0 0 0 13px" }}
              />
              <QRCode
                size={160}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrLink}
                viewBox="0 0 160 160"
              />
            </div>
            <div style={{
              fontSize: "18px"
            }}>
              {content}
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <SimpleModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          closeBtnClassName={styles.close}
        >
          <div className={styles.container}>
            <div className={styles.title}>
              {content}
            </div>
            <div className={styles.content}>
              <div className={styles.qrCode}>
                <div
                  className={classNames(styles.top, styles.left)}
                  style={{ borderRadius: "13px 0 0 0" }}
                />
                <div
                  className={classNames(styles.top, styles.right)}
                  style={{ borderRadius: "0 13px 0 0" }}
                />
                <div
                  className={classNames(styles.bottom, styles.right)}
                  style={{ borderRadius: "0 0 13px 0" }}
                />
                <div
                  className={classNames(styles.bottom, styles.left)}
                  style={{ borderRadius: "0 0 0 13px" }}
                />
                <QRCode
                  size={160}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrLink}
                  viewBox="0 0 160 160"
                />
              </div>
            </div>
            <button className={styles.closeBtn} onClick={closeQR}>
              {getTranslation("сloseButton")}
            </button>
          </div>
        </SimpleModal>
      )}
    </>
  )
}

export default QRField

import { useContext, useEffect, useState } from "react"

import { useMemo } from "preact/hooks"
import { API } from "@/common/api"

import usePageContext from "@/common/hooks/usePageState"
import usePaymentStateContext from "@/common/hooks/usePaymentState"

import FailPage from "@/pages/FailPage"
import LoaderPage from "@/pages/LoaderPage"
import PaymentPage from "@/pages/PaymentPage"

import { OrderStatusCode } from "@/common/enums"

import "@/common/styles/global.scss"
import styles from "./styles.module.scss"
import { ITransactionInfo, NewResponse, RedirectType } from "@/common/types"
import SuccessPage from "@/pages/SuccessPage"
import { LanguageContext } from "@/common/contexts/langState"
import { SectionsContext } from "@/common/contexts/sectionsState"
import { AxiosResponse } from "axios"

const PaymentGateWebWidget = ({ orderId, redirectType }: PaymentGateWebWidgetProps) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<null | string>(null)

  const {
    orderData,
    message,
    statusCode,
    redirectUrl,
    changePaymentState,
    setMessage,
    setStatusCode,
    setUrl,
  } = usePaymentStateContext()
  const { page, changePage } = usePageContext()

  const { setLang, loading: langLoading, getTranslation } = useContext(LanguageContext);

  const { currentSectionNumber: section_position, updatePageInfo, setPageId, getSectionNumber, pageId } = useContext(SectionsContext);

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setPageId(orderId);
  }, [orderId])

  const syncSectionNumber = getSectionNumber();

  const processResponse = (resp: AxiosResponse<NewResponse<ITransactionInfo>, any>) => {
    if (resp?.data) {
      const { data, code, message: respMessage, status, error_message: errorMsg, errors } = resp?.data
      if (status === "success") {
        setStatusCode(code)
        setMessage(respMessage)
        changePaymentState(data)
        if (data.flow_type === "step" && pageId && !section_position) {
          updatePageInfo(data.sections[0].position, orderId)
        }
        setLang(data.lang)
      } else {
        setLoading(false)
        if (code === 40000) {
          setError(errors[0].message)
        } else {
          setError(errorMsg || "") 
        }
      }
      setFetching(false);
    }
  }

  useEffect(() => {
    if (!redirectType && pageId && !syncSectionNumber) {
      setLoading(true);
      setFetching(true);
      API.getOrder(orderId)
        .then(processResponse)
        .catch(() => {
          setLoading(false)
          setError("Unknown error")
        })
    }
  }, [pageId, section_position, redirectType])

  useEffect(() => {
    if (section_position) {
      setLoading(true);
      setFetching(true);
      API.getOrder(orderId, { section_position })
        .then(processResponse)
    }
  }, [orderId, section_position])

  useEffect(() => {
    if (redirectType) {
      API.getRedirectUrl(orderId, redirectType)
        .then(resp => {
          const { code, status, message: respMessage, data, error_message: errMsg, errors } = resp.data
          const url = data?.url || window.location.href.split("/redirectUrl")[0]
          setUrl(url)
          setStatusCode(code)
          if (status === "success") {
            setMessage(respMessage)
          } else {
            if (code === 40000) {
              setMessage(errors[0].message)
            } else {
              setMessage(errMsg || "") 
            }
          }
        })
        .catch(err => {
          const { data } = err.response
          setStatusCode(data.code)
          const url = data.url || window.location.href.split("/redirectUrl")[0]
          setUrl(url)
          setMessage("Unknown error")
        })
    }
  }, [orderId, redirectType])

  useEffect(() => {
    if (redirectType && message && statusCode) {
      setLoading(false)
      if (redirectUrl) {
        if (statusCode === OrderStatusCode.Active && redirectType === "success") {
          window.location.href = redirectUrl
        } else if (statusCode === OrderStatusCode.Proceed) {
          switch (redirectType) {
            case "fail":
              changePage(
                <FailPage
                  message={getTranslation("paymentCanceled")}
                  secondaryMessage={getTranslation("hintPaymentFailed")}
                  redirectButtonUrl={redirectUrl}
                />,
              )
              break
            case "cancel":
              changePage(<FailPage message={getTranslation("paymentCanceled")} redirectButtonUrl={redirectUrl} />)
              break
            case "success":
            default:
              changePage(<SuccessPage />)
              break
          }
        } else {
          changePage(<FailPage message={message} redirectButtonUrl={redirectUrl} />)
        }
      } else {
        switch (statusCode) {
          case OrderStatusCode.Completed:
          case OrderStatusCode.Expired:
          case OrderStatusCode.NotFound:
          case OrderStatusCode.SystemFail:
          case OrderStatusCode.SystemFail2:
          case OrderStatusCode.SystemFail3:
            changePage(<FailPage message={message} />)
            break
          case OrderStatusCode.Proceed:
            changePage(<SuccessPage />)
            break
          default:
            break
        }
      }
    }
  }, [redirectType, message, statusCode, redirectUrl])

  useEffect(() => {
    if (orderData && message && statusCode) {
      setLoading(false)
      switch (statusCode) {
        case OrderStatusCode.Completed:
        case OrderStatusCode.Expired:
          changePage(<FailPage message={message} />)
          break
        default:
          changePage(<PaymentPage {...orderData} />)
          break
      }
    }
  }, [orderData, message, statusCode, fetching])

  const content = useMemo(() => {
    if ((loading || langLoading || fetching) && !error) return <LoaderPage />
    if (error) return <FailPage message={error} />
    return page
  }, [loading, langLoading, error, page])

  return (
    <div className={styles.layout}>
      <div className={styles.container}>{content}</div>
    </div>
  )
}

export default PaymentGateWebWidget

type PaymentGateWebWidgetProps = {
  orderId: string
  redirectType: RedirectType | null
}

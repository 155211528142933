import React, { useCallback, useEffect, useState } from "react"

const LOCAL_STORAGE_KEY = "GATEWAY_SECTIONS";

type SectionInfo = {
  pageId: string;
  section_number: number;
  date: string;
}

export const SectionsContext = React.createContext<SectionsContextType>({
  currentSectionNumber: 0,
  updatePageInfo: () => {},
  setPageId: () => {},
  pageId: "",
  increaseSectionNumber: () => {},
  decreaseSectionNumber: () => {},
  getSectionNumber: () => {},
})

const SectionsContextProvider = ({ children }: any) => {

  const [pageId, setPageId] = useState<string>("");

  const getData = (): SectionInfo[] => {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY)

    if (!data) return [];

    return JSON.parse(data);
  }

  const sectionInfo = getData();

  const setInfo = (info: SectionInfo[]) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(info));
  }

  const bumpDate = (data: SectionInfo[]): SectionInfo[] => {
    const foundIndex = data.findIndex((sectionInfo) => sectionInfo.pageId === pageId);
    if (foundIndex > -1) {
      data[foundIndex].date = new Date().toISOString()
    }
    return data;
  }

  const clearData = (info: SectionInfo[]): SectionInfo[] => {
    const isToday = (milliseconds: number) => {
      return new Date().getTime() - milliseconds < 1000 * 60 * 60 * 24
    }

    return info.filter((sectionInfo) => isToday(new Date(sectionInfo.date).getTime()))
  }

  const refreshInfo = (data: SectionInfo[]) => {
    data = bumpDate(data);
    data = clearData(data);
    setInfo(data || []);
  }

  const getSectionNumber = useCallback((): number => {
    const foundInfo = sectionInfo.find((sectionInfo) => sectionInfo.pageId === pageId);

    if (!foundInfo) return 0;

    refreshInfo(sectionInfo);

    return foundInfo.section_number;
  }, [pageId, sectionInfo]);

  const updatePageInfo = useCallback((section_number?: number, pageID = pageId) => {
    if (section_number) {
      const foundIndex = sectionInfo.findIndex((sectionInfo) => sectionInfo.pageId === pageID);
      if (foundIndex > -1) {
        sectionInfo[foundIndex].section_number = section_number;
      } else if (pageID) {
        sectionInfo.push({
          pageId: pageID,
          section_number,
          date: new Date().toISOString(),
        })
      }
  
      refreshInfo(sectionInfo);
    }
  }, [pageId, sectionInfo])

  const [currentSectionNumber, setSectionNumber] = useState(getSectionNumber());

  useEffect(() => {
    setSectionNumber(getSectionNumber())
  }, [pageId])

  useEffect(() => {
    updatePageInfo(currentSectionNumber)
  }, [currentSectionNumber, sectionInfo.length]);

  const increaseSectionNumber = () => {
    setSectionNumber((section) => {
      if (!section) return 2
      return section + 1
    })
  }

  const decreaseSectionNumber = () => {
    setSectionNumber((section) => section - 1)
  }


  return (
    <SectionsContext.Provider value={{ 
      currentSectionNumber,
      updatePageInfo,
      setPageId,
      getSectionNumber,
      pageId,
      increaseSectionNumber,
      decreaseSectionNumber,
    }}>
        {children}
    </SectionsContext.Provider>
  )
}

export default SectionsContextProvider;

export type SectionsContextType = {
  currentSectionNumber: number;
  updatePageInfo: (section_number?: number, pageId?: string) => void;
  setPageId: (pageId: string) => void;
  pageId: string;
  getSectionNumber: any
  increaseSectionNumber: () => void;
  decreaseSectionNumber: () => void;
}
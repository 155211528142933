import { DigicertIcon, DsecureIcon, PciIcon } from "../atoms/Icons"
import styles from "./styles.module.scss"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <DigicertIcon className={styles.digicert} />
      <DsecureIcon className={styles.dsecure} />
      <PciIcon className={styles.pci} />
    </footer>
  )
}

export default Footer

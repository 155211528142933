import classNames from "classnames"
import { useState } from "react"
import { CloseBtnIcon, ShowLogoIcon } from "../atoms/Icons"
import SimpleModal from "../atoms/SimpleModal"

import usePlausible from "@/common/hooks/usePlausible"
/* eslint-disable camelcase */
import useScreenSize, { ScreenSize } from "@/common/hooks/useScreenSize"

import { PlausibleEvent } from "@/common/enums"

import styles from "./styles.module.scss"

interface IProps {
  paymentGuide: string
  name: string
}

const PaymentInstructions = ({ paymentGuide, name }: IProps) => {
  const setEvent = usePlausible()

  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const isMobile = useScreenSize() === ScreenSize.MOBILE

  const onOpen = () => {
    setShowTooltip(true)
    setEvent(PlausibleEvent.OpenInstructions)
  }

  const onClose = () => {
    setShowTooltip(false)
    setEvent(PlausibleEvent.CloseInstructions)
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.panel}>
          <div style={{ position: "relative" }}>
            <button className={styles.instructions} onClick={onOpen}>
              <div className={styles.icon}>
                <ShowLogoIcon />
              </div>
              <div className={styles.text}>{name}</div>
            </button>
            {!isMobile && showTooltip && (
              <div className={classNames(styles.tooltip, styles.instructionsTooltip)}>
                <button onClick={onClose}>
                  <CloseBtnIcon />
                </button>
                <img src={paymentGuide} alt="Tip" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile && (
        <SimpleModal isOpen={showTooltip} onClose={onClose}>
          <img className={styles.tooltipImg} src={paymentGuide} alt="Tip" />
        </SimpleModal>
      )}
    </>
  )
}

export default PaymentInstructions

import { useCallback, useContext } from "react"
import { LeftArrowIcon } from "../atoms/Icons"

import usePlausible from "@/common/hooks/usePlausible"

import { PlausibleEvent } from "@/common/enums"

import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

interface IProps {
  redirectReturnUrl?: string
}
const Header = ({ redirectReturnUrl }: IProps) => {
  const setEvent = usePlausible()
  const { getTranslation } = useContext(LanguageContext)

  const toReturnUrl = useCallback(() => {
    if (redirectReturnUrl) {
      setEvent(PlausibleEvent.ClickToBackButton)
      window.location.href = redirectReturnUrl
    }
  }, [redirectReturnUrl])

  return (
    <header className={styles.header}>
      {redirectReturnUrl && (
        <button className={styles.button} onClick={() => toReturnUrl()}>
          <LeftArrowIcon />
          <span className={styles.label}>{getTranslation("backButton")}</span>
        </button>
      )}
    </header>
  )
}

export default Header

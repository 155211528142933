import React, { useState } from "react"

export const PageContext = React.createContext<PageContextType>({
  page: null,
  changePage: () => {},
})

const PageContextProvider = ({ children }: any) => {
  const [page, setPage] = useState<JSX.Element | null>(null)

  const changePage = (state: JSX.Element) => setPage(state)

  return <PageContext.Provider value={{ page, changePage }}>{children}</PageContext.Provider>
}

export default PageContextProvider

export type PageContextType = {
  page: JSX.Element | null
  changePage: (state: JSX.Element) => void
}

/* eslint-disable no-plusplus */
const mockOrder = "23e2639b-ea23-403c-9233-536eb00d3a9e"

export const getOrderId = () => {
  const pathName = window.location.pathname
  const pathParts = pathName.split("/orders/")
  const orderId = pathParts[1]
  return orderId || mockOrder
}

export const camelize = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export const createImmuteId = (): string => {
  let immuteId = ``

  for (let i = 0; i < 32; i++) {
    const mode = Math.random()
    if (mode < 0.5) {
      immuteId += String.fromCharCode(Math.floor(48 + Math.random() * 10))
    } else {
      const letterMode = Math.random()
      const base = letterMode < 0.5 ? 65 : 97
      immuteId += String.fromCharCode(Math.floor(base + Math.random() * 26))
    }
  }

  return immuteId
}

export const formatTime = (t: number): string => {
  const formatDigit = (digit: number) => {
    let strDigit = digit.toString()
    if (strDigit.length === 1) {
      strDigit = `0${strDigit}`
    }

    return strDigit
  }

  const minutes = Math.floor(t / 1000 / 60)
  const seconds = (t - minutes * 1000 * 60) / 1000

  return `${formatDigit(minutes)}:${formatDigit(seconds)}`
}

export const formatAmount = (amount: number) => {
  // eslint-disable-next-line prefer-const
  let [decimalPart, fractionPart] = amount.toString().split(".")
  let formattedDecimalPart = ``
  while (decimalPart.length >= 4) {
    formattedDecimalPart = `,${decimalPart.substr(
      decimalPart.length - 3,
      3,
    )}${formattedDecimalPart}`
    decimalPart = decimalPart.substring(0, decimalPart.length - 3)
  }
  formattedDecimalPart = decimalPart + formattedDecimalPart
  if (fractionPart) {
    return `${formattedDecimalPart}.${fractionPart}`
  }
  return formattedDecimalPart
}

export const isMobile = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

import { AxiosPromise } from "axios"
import { fetchApi } from "./fetchApi"
import {
  ITransactionInfo,
  NewResponse,
  RedirectData,
  RedirectType,
  RedirectUrlResponse,
  TransactionData,
  TransactionResponse,
} from "../types"
import { isMobile } from "@/utils"

export const API = {
  getOrder: (orderId: string, data = {}): AxiosPromise<NewResponse<ITransactionInfo>> => {
    const device_type = isMobile() ? "mobile" : "desktop"
    return fetchApi(`/api/v1/orders/${orderId}`, "GET", { device_type, ...data })
  },

  makeTransaction: (
    orderId: string,
    data: TransactionData,
  ): AxiosPromise<NewResponse<TransactionResponse>> =>
    fetchApi(`/api/v1/orders/${orderId}/transaction`, "POST", data),

  makePossibleTransaction: (orderId: string, data: TransactionData) =>
    fetchApi(`/api/v1/orders/${orderId}/possible_transaction`, "POST", data),

  getRedirectUrl: (
    orderId: string,
    redirectType: RedirectType,
  ): AxiosPromise<NewResponse<RedirectUrlResponse>> =>
    fetchApi(`/api/v1/orders/${orderId}/redirect_url?type=${redirectType}`, "GET"),

  getTranslations: (lang: string): AxiosPromise<Record<string, string>> =>
    fetchApi(`/storage/locale/${lang}.json`, "GET"),

  makeRedirect: (orderId: string, data: RedirectData): AxiosPromise<NewResponse<{ url: string }>> =>
    fetchApi(`/api/v1/orders/${orderId}/redirect_ps`, "POST", data),
}

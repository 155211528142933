import { FormikErrors } from "formik"
import { API } from "../api"

import { createImmuteId, formatTime, getOrderId } from "@/utils"
import { ITransactionInfo, ITransactionParams } from "../types"

import { transactionMakeHandler } from "@/components/PaymentForm"
import { TransactionInfo } from "../enums"
import CreditedPage from "@/pages/CreditedPage"

interface IProps {
  transactionSearchTimerForUser: ITransactionInfo["transaction_search_timer_for_user"]
  transactionSearchFrequency: ITransactionInfo["transaction_search_frequency"]
  redirectSuccessUrl: ITransactionInfo["redirect_url"]["success"]
  redirectReturnUrl: ITransactionInfo["redirect_url"]["return"]
  params: ITransactionParams[]
  setPage: (page: JSX.Element) => void
  setErrors: (errors: FormikErrors<any>) => void
  setTime: (time: string) => void
  setDisableButton: (disable: boolean) => void
  setMessage?: () => void
}

const useTimer = ({
  transactionSearchTimerForUser,
  transactionSearchFrequency,
  redirectReturnUrl,
  redirectSuccessUrl,
  params,
  setPage,
  setErrors,
  setTime,
  setDisableButton,
  // После истечения таймера, нужно показать сообщение над кнопкой.
  setMessage,
}: IProps) => {
  let currTime = +transactionSearchTimerForUser * 1000

  const refreshInterval = setInterval(() => {
    setTime(formatTime(currTime))
    currTime -= 1000
    if (currTime < 0) {
      clearInterval(refreshInterval)
    }

    const completedTime = +transactionSearchTimerForUser * 1000 - currTime
    const parsedFrequency = +transactionSearchFrequency * 1000

    if (completedTime % parsedFrequency === 0) {
      API.makeTransaction(getOrderId(), {
        params,
        immute_id: createImmuteId(),
      }).then(resp => {
        transactionMakeHandler({
          resp,
          redirectSuccessUrl,
          redirectReturnUrl,
          setPage,
          onMissing: async data => {
            if (currTime <= 0) {
              if (data.code === TransactionInfo.TransactionNotFound) {
                const { data: possibleTransactionData } = await API.makePossibleTransaction(
                  getOrderId(),
                  {
                    params,
                    immute_id: createImmuteId(),
                  },
                )

                if (
                  possibleTransactionData?.code === TransactionInfo.DefaultTemplate ||
                  possibleTransactionData?.code === TransactionInfo.PossibleTransactionAlreadyExists
                ) {
                  setPage(<CreditedPage redirectReturnUrl={redirectReturnUrl} />)
                }

                if (
                  possibleTransactionData.code ===
                    TransactionInfo.PossibleTransactionsDisabledForPS ||
                  possibleTransactionData.code ===
                    TransactionInfo.ReachedTheLimitNumbersOfPossibleTransactions ||
                  possibleTransactionData.code === TransactionInfo.AnyReasonError ||
                  possibleTransactionData.code === TransactionInfo.CreatingPossibleTransaction
                ) {
                  if (setMessage) {
                    setMessage()
                    setTime("")
                  }
                }

                return
              }

              setDisableButton(false)
              setErrors({ formError: data.message })
              setTime("")
              clearInterval(refreshInterval)
            }
          },
        })
      })
    }
  }, 1000)
}

export default useTimer

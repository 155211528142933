import { CopyIcon } from "../Icons"

import usePlausible from "@/common/hooks/usePlausible"

import { PlausibleEvent } from "@/common/enums"

import styles from "./styles.module.scss"
import { useState } from "react"

export interface TextInputProps {
  value?: string
  label?: string
  name: string
}

export const CopyInput = ({ label, value, name }: TextInputProps) => {
  const setEvent = usePlausible()

  const [showCopied, setShowCopied] = useState<boolean>(false);

  const copy = () => {
    navigator.clipboard.writeText(`${value}`)
    setEvent(PlausibleEvent.ClickToCopyButton, { name })
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 3000)
  }

  return (
    <div className={styles.wrapper}>
      <div style={{ minHeight: "56px" }} className={styles.copyInput}>
        <label className={styles.label}>{label}</label>
        <button className={styles.copy} onClick={copy} disabled={!value}>
          <span className={styles.value} data-test-id="wallet-number-value">{value}</span>
          <div className={styles.icon}>
            <CopyIcon active={showCopied}/>
          </div>
        </button>
      </div>
      <div className={styles.copiedWrapper}>
        {showCopied && (
          <>Copied</>
        )}
      </div>
    </div>
  )
}

export default CopyInput

import React, { useState } from "react"

import { ITransactionInfo } from "../types"

export const PaymentContext = React.createContext<PaymentStateContextType>({
  orderData: null,
  statusCode: null,
  message: null,
  redirectUrl: null,
  setMessage: () => {},
  setStatusCode: () => {},
  changePaymentState: () => {},
  setUrl: () => {},
})

const PaymentContextProvider = ({ children }: any) => {
  const [orderData, setOrderData] = useState<ITransactionInfo | null>(null)
  const [statusCode, setStatusCode] = useState<number | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const [redirectUrl, setUrl] = useState<string | null>(null)

  const changePaymentState = (changes: ITransactionInfo) => {
    setOrderData(oldState => {
      return { ...oldState, ...changes }
    })
  }

  return (
    <PaymentContext.Provider
      value={{
        orderData,
        statusCode,
        message,
        redirectUrl,
        setStatusCode,
        setMessage,
        setUrl,
        changePaymentState,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export default PaymentContextProvider

export type PaymentStateContextType = {
  orderData: ITransactionInfo | null
  statusCode: number | null
  message: string | null
  redirectUrl: string | null
  changePaymentState: (state: ITransactionInfo) => void
  setStatusCode: (status: number) => void
  setMessage: (message: string) => void
  setUrl: (url: string) => void
}

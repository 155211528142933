/* eslint-disable array-callback-return */
import { AxiosError, AxiosResponse } from "axios"
/* eslint-disable camelcase */
import classNames from "classnames"
import { useFormik } from "formik"
import { useContext, useMemo } from "react"
import * as Yup from "yup"
import { useState } from "preact/hooks"
import AmountInput from "../atoms/AmountInput"
import CopyInput from "../atoms/CopyInput"
import QRField from "../atoms/QRField"
import TextInput from "../atoms/TextInput"
import Timer from "../atoms/Timer"
import CleaveComponent from "../atoms/Cleave"

import { API } from "@/common/api"

import usePageContext from "@/common/hooks/usePageState"
import usePlausible from "@/common/hooks/usePlausible"
import useTimer from "@/common/hooks/useTimer"

import AccumulatedPage from "@/pages/AccumulatedPage"
import CreditedPage from "@/pages/CreditedPage"
import SuccessPage from "@/pages/SuccessPage"

import { ComponentType, PlausibleEvent, TransactionInfo } from "@/common/enums"
import {
  IComponent,
  IRow,
  ISection,
  ITransactionInfo,
  ITransactionParams,
  NewResponse,
  TransactionAccumulatedResponse,
  TransactionResponse,
} from "@/common/types"
import { createImmuteId, getOrderId } from "@/utils"

import styles from "./styles.module.scss"
import PaymentInstructions from "@/components/PaymentInstructions"
import FailPage from "@/pages/FailPage"
import { SectionsContext } from "@/common/contexts/sectionsState"

export type HandlerProps = {
  resp: AxiosResponse<NewResponse<TransactionResponse | TransactionAccumulatedResponse>>
  redirectSuccessUrl: ITransactionInfo["redirect_url"]["success"]
  redirectReturnUrl: ITransactionInfo["redirect_url"]["return"]
  onMissing: (data: TransactionResponse) => void
  setPage: (page: JSX.Element) => void
}

export const transactionMakeHandler = ({
  resp,
  redirectSuccessUrl,
  redirectReturnUrl,
  onMissing,
  setPage,
}: HandlerProps) => {
  const { data: transactionInfo } = resp

  // @ts-ignore
  if (transactionInfo.code === TransactionInfo.TransactionNotFound) {
    // @ts-ignore
    onMissing(transactionInfo)
    // @ts-ignore
  } else if (transactionInfo.code === TransactionInfo.Stockpiling) {
    // @ts-ignore
    setPage(<AccumulatedPage {...transactionInfo.data} />)
    // @ts-ignore
  } else if (transactionInfo.code === TransactionInfo.TransactionFound) {
    setPage(<SuccessPage redirectSuccessUrl={redirectSuccessUrl} />)
    // @ts-ignore
  }
}

interface IProps {
  sections: ISection[]
  redirectSuccessUrl: ITransactionInfo["redirect_url"]["success"]
  redirectReturnUrl: ITransactionInfo["redirect_url"]["return"]
  transactionSearchTimerForUser: ITransactionInfo["transaction_search_timer_for_user"]
  transactionSearchFrequency: ITransactionInfo["transaction_search_frequency"]
  symbol: ITransactionInfo["symbol"]
  amount: ITransactionInfo["amount"]
  currencyCode: ITransactionInfo["currency_code"]
  flow_type: ITransactionInfo["flow_type"]
}

const PaymentForm = ({
  sections,
  redirectReturnUrl,
  redirectSuccessUrl,
  transactionSearchFrequency,
  transactionSearchTimerForUser,
  symbol,
  amount,
  currencyCode,
  flow_type,
}: IProps) => {
  const setEvent = usePlausible()
  const [time, setTime] = useState<string>("")
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const { increaseSectionNumber, decreaseSectionNumber } = useContext(SectionsContext)

  const { changePage } = usePageContext()

  const getComponents = (rows: IRow[]): IComponent[] => {
    return (rows || []).reduce((acc, row) => [...acc, ...row.components], [] as IComponent[])
  }

  const initialValues = useMemo(() => {
    const init: any = {}
    ;(sections || []).map(({ rows }) =>
      getComponents(rows).map(({ code_name, value, disabled, type }) => {
        if (
          !disabled &&
          type !== ComponentType.AMOUNT &&
          type !== ComponentType.TEXT &&
          code_name
        ) {
          init[code_name] = value
        }
      }),
    )
    return init
  }, [sections])

  const validationSchema = useMemo(() => {
    const init: any = {}
    ;(sections || []).map(({ rows }) =>
      getComponents(rows).map(({ code_name, regexp, regexp_error, type }) => {
        if (regexp && code_name) {
          init[code_name] = Yup.string()
            .required(`Field is required`)
            .matches(new RegExp(regexp), regexp_error)
            .nullable()
        }
        if (type === ComponentType.AMOUNT) {
          init.amount = Yup.number()
            .typeError(`Amount must be only number`)
            .required(`Field is required`)
            .nullable()
        }
      }),
    )
    return Yup.object(init)
  }, [sections])

  const submitHandler = (values: any) => {
    const isRedirectSubmit = values.submitType && values.submitType === "redirect"

    delete values.submitType

    const fields = sections
      .map(section =>
        getComponents(section.rows)
          .filter(row => !row.disabled && row.code_name)
          .filter(row => !!values[row.code_name || ""]),
      )
      .reduce((prev, curr) => [...prev, ...curr])

    setDisableButton(true)
    setEvent(PlausibleEvent.ClickToConfirmButton)

    if (isRedirectSubmit) {
      const components = fields.map(({ id, code_name }) => {
        return {
          id,
          value: `${values[code_name || ""]}`,
        }
      })
      API.makeRedirect(getOrderId(), {
        immute_id: createImmuteId(),
        components,
      }).then(resp => {
        if (resp.data.status === "success") {
          window.location.href = resp.data.data.url
        } else {
          changePage(<FailPage message={resp.data.error_message || ``} />)
        }
      })
    } else {
      const data = fields.map(row => ({ code_name: row.code_name || ``, component_id: row.id }))

      const params = data.map(({ code_name, component_id }) => {
        const payload: ITransactionParams = {
          component_id,
          value: `${values[code_name]}`,
        }

        return payload
      })
      API.makeTransaction(getOrderId(), {
        immute_id: createImmuteId(),
        params,
      })
        .then(resp => {
          transactionMakeHandler({
            resp,
            redirectSuccessUrl,
            redirectReturnUrl,
            setPage: changePage,
            onMissing: () => {
              useTimer({
                transactionSearchFrequency,
                transactionSearchTimerForUser,
                redirectReturnUrl,
                redirectSuccessUrl,
                params,
                setPage: changePage,
                setErrors,
                setTime,
                setDisableButton,
                setMessage: () =>
                  setErrorMsg(
                    "Transaction not found or was already activated. Please check the entered data and try again later",
                  ),
              })
            },
          })
        })
        .catch((e: AxiosError<TransactionResponse>) => {
          if (e.response) {
            const {
              response: { data, status },
            } = e

            if (status === 400) {
              setErrors({ formError: data.message })
            }
          }
        })
    }
  }

  const {
    handleChange,
    values: formikValues,
    errors,
    handleBlur,
    submitForm,
    isValid,
    dirty,
    setErrors,
    setFieldValue,
  } = useFormik({
    onSubmit: submitHandler,
    validationSchema,
    initialValues,
  })

  return (
    <div className={styles.paymentForm}>
      {sections.map(({ rows, title }, index) => (
        <div className={styles.section} key={title || index}>
          {title && <div className={styles.step}>{title}</div>}
          <div className={styles.panel}>
            {rows.map(row => {
              return (
                <div className={styles.row}>
                  {row.components.map(
                    ({ code_name, disabled, value, type, content, src, is_hidden }) => {
                      switch (type) {
                        case ComponentType.PAYMENT_GUIDE:
                          return (
                            <PaymentInstructions name={content || ``} paymentGuide={src || ``} />
                          )
                        case ComponentType.TEXT:
                          return (
                            <div className={styles.component}>
                              <div className={styles.hint}>{content}</div>
                            </div>
                          )
                        case ComponentType.AMOUNT:
                          return (
                            <div className={styles.component}>
                              <AmountInput
                                label="Amount"
                                name="amount"
                                symbol={symbol}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.amount}
                                key="amount"
                              />
                            </div>
                          )
                        case ComponentType.QR:
                          // eslint-disable-next-line no-case-declarations
                          const qrLink = `upi://pay?pa=${value}&pn=pk${
                            amount ? `&am=${amount}&cu=${currencyCode}` : ``
                          }`
                          return (
                            <div className={styles.component}>
                              <QRField content={content || ""} qrLink={qrLink} />
                            </div>
                          )
                        case ComponentType.NEXT_BTN:
                          return (
                            <div className={styles.component}>
                              <div className={styles.wrapper}>
                                <button
                                  className={classNames(styles.button, styles.active)}
                                  onClick={increaseSectionNumber}
                                >
                                  {content}
                                </button>
                              </div>
                            </div>
                          )
                        case ComponentType.BACK_BTN:
                          return (
                            <div className={styles.component}>
                              <div className={styles.wrapper}>
                                <button
                                  className={classNames(styles.button, styles.active)}
                                  onClick={decreaseSectionNumber}
                                >
                                  {content}
                                </button>
                              </div>
                            </div>
                          )
                        case ComponentType.REDIRECT_BTN:
                          return (
                            <div className={styles.component}>
                              <div className={styles.wrapper}>
                                <button
                                  className={classNames(styles.button, {
                                    [styles.active]: !disableButton && dirty && isValid,
                                  })}
                                  disabled={disableButton || !dirty || !isValid}
                                  onClick={() => {
                                    setFieldValue("submitType", "redirect", false)
                                    submitForm()
                                  }}
                                >
                                  {content}
                                </button>
                              </div>
                            </div>
                          )
                        case ComponentType.REDIRECT_UPI_BTN:
                          return (
                            <div className={styles.component}>
                              <div className={styles.wrapper}>
                                <button className={styles.upiButton}>
                                  <a
                                    className={styles.upiLink}
                                    href={value}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {content}
                                  </a>
                                </button>
                              </div>
                            </div>
                          )
                        case ComponentType.CONFIRM_P2P:
                          return (
                            <div className={styles.component}>
                              <div className={styles.wrapper}>
                                <p className={styles.errorMsg} hidden={!errorMsg}>
                                  {errorMsg}
                                </p>
                                <button
                                  className={classNames(styles.button, {
                                    [styles.active]: !disableButton && dirty && isValid,
                                  })}
                                  disabled={disableButton || !dirty || !isValid}
                                  onClick={() => submitForm()}
                                  data-test-id="verify-button"
                                >
                                  {content}
                                </button>
                              </div>
                            </div>
                          )
                        case ComponentType.CARD_NUMBER:
                          if (code_name) {
                            return (
                                <TextInput
                                  label={content}
                                  name={code_name}
                                  tooltip={src}
                                  value={formikValues[code_name]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors[code_name]}
                                  key={code_name}
                                  input={CleaveComponent}
                                />
                            )
                          }
                          return null;
                        case ComponentType.TEXT_FIELD:
                        default:
                          if (is_hidden) return null
                          return (
                            <>
                              {code_name && (
                                <div className={styles.component}>
                                  {!disabled ? (
                                    <TextInput
                                      label={content}
                                      name={code_name}
                                      tooltip={src}
                                      value={formikValues[code_name]}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={errors[code_name]}
                                      key={code_name}
                                    />
                                  ) : (
                                    <CopyInput
                                      label={content}
                                      value={value}
                                      name={code_name}
                                      key={code_name}
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )
                      }
                    },
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ))}
      <div className={styles.error}>{errors.formError && errors.formError}</div>
      {time && <Timer time={time} />}
    </div>
  )
}

export default PaymentForm

/* eslint-disable camelcase */
import Plausible from "plausible-tracker"
import usePaymentStateContext from "./usePaymentState"
import { PlausibleEvent } from "../enums"
import { JSON_CONFIG } from "../api/fetchApi"

const { trackEvent } = Plausible()

const usePlausible = () => {
  const { orderData } = usePaymentStateContext()

  const props = {
    paymentMethod: orderData?.payment_method || "",
    orderId: orderData?.order_id || "",
    payerId: orderData?.payer_id || "",
    templateId: orderData?.template_id || "",
  }

  const setEvent = (plausibleEvent: PlausibleEvent, otherProps?: Record<string, any>) => {
    if (JSON_CONFIG.PLAUSIBLE_ENABLED) {
      trackEvent(
        plausibleEvent,
        {
          props: { ...props, ...otherProps },
        },
        {
          trackLocalhost: true,
          apiHost: JSON_CONFIG.PLAUSIBLE_API_HOST,
        },
      )
    }
  }

  return setEvent
}

export default usePlausible

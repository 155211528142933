import { useCallback, useContext, useEffect } from "react"

import usePlausible from "@/common/hooks/usePlausible"

import { PlausibleEvent } from "@/common/enums"

import Footer from "@/components/Footer"
import Header from "@/components/Header"
import { FailIcon } from "@/components/atoms/Icons"

import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

interface IProps {
  message: string
  secondaryMessage?: string
  redirectReturnUrl?: string
  redirectButtonUrl?: string
}

const FailPage = ({ message, secondaryMessage, redirectButtonUrl, redirectReturnUrl }: IProps) => {
  const setEvent = usePlausible()
  const { getTranslation } = useContext(LanguageContext);
  
  const toReturnUrl = useCallback(() => {
    if (redirectButtonUrl) {
      setEvent(PlausibleEvent.ClickToBackButton)
      window.location.href = redirectButtonUrl
    }
  }, [redirectButtonUrl])

  useEffect(() => {
    setEvent(PlausibleEvent.OpenErrorPage)
  }, [])

  return (
    <>
      <Header redirectReturnUrl={redirectReturnUrl} />
      <div className={styles.failPage}>
        <div className={styles.icon}>
          <FailIcon />
        </div>
        <div className={styles.title}>{message}</div>
        {secondaryMessage && <div className={styles.secondaryMessage}>{secondaryMessage}</div>}
        {redirectButtonUrl && (
          <button className={styles.button} onClick={() => toReturnUrl()}>
            {getTranslation("button")}
          </button>
        )}
      </div>
      <Footer />
    </>
  )
}

export default FailPage

import axios, { AxiosPromise, AxiosRequestConfig, Method } from "axios"

export const getConfig = () => {
  return axios('/config.json');
}

const conf = getConfig()
export let JSON_CONFIG: Record<string, string> = {}

export const fetchApi = (url: string, method: Method, data?: any): AxiosPromise => {
  return conf.then((c) => {
    JSON_CONFIG = c.data;
    const config: AxiosRequestConfig = {
      headers: {
        [`Request-Version`]: `1.000.0`,
      },
      baseURL: JSON_CONFIG.BACKEND_HOST,
      url,
      method,
      // headers: {
      //     "api_key": "VkUh0wFOW7pYoSmgTQysex3NEHdXi45j",
      // }
    }
  
    if (data) {
      if (method === "GET") {
        config.params = data
      } else {
        config.data = data
      }
    }
    return axios(config)
  })
}

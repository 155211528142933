import { render } from "preact/compat"
import PaymentGateWebWidget from "./widget"

import PageContextProvider from "./common/contexts/pageState"
import PaymentContextProvider from "./common/contexts/paymentState"
import LanguageContextProvider from "./common/contexts/langState"
import { RedirectType } from "@/common/types"
import SectionsContextProvider from "./common/contexts/sectionsState"

window.PaymentGateWebWidget = ({
  container,
  orderId,
  redirectType,
}: WidgetOptionType): WidgetReturnType | Error => {
  if (!container) {
    return new Error("Invalid container. Please check the container parameter.")
  }

  render(
    <PaymentContextProvider>
      <PageContextProvider>
        <LanguageContextProvider>
          <SectionsContextProvider>
            <PaymentGateWebWidget {...{ orderId, redirectType }} />
          </SectionsContextProvider>
        </LanguageContextProvider>
      </PageContextProvider>
    </PaymentContextProvider>,
    container,
  )

  return {
    destroy: () => render(null as any, container),
  }
}

declare global {
  interface Window {
    PaymentGateWebWidget: Function
  }
}

type WidgetOptionType = {
  container: HTMLElement
  orderId: string
  redirectType: RedirectType | null
}

type WidgetReturnType = {
  destroy: Function
}

import { useEffect, useMemo, useState } from 'preact/hooks'

const MOBILE_WIDTH = 450

export enum ScreenSize {
  MOBILE,
  DESKTOP,
}
const useScreenSize = (): ScreenSize => {
  const [width, setWidth] = useState(window.innerWidth)

  const onResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const screenSize = useMemo(() => {
    if (width < MOBILE_WIDTH) {
      return ScreenSize.MOBILE
    } else {
      return ScreenSize.DESKTOP
    }
  }, [width])

  return screenSize
}

export default useScreenSize

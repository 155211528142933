import { useContext } from "react"
import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

export const formatAmount = (amount: number) => {
  // eslint-disable-next-line prefer-const
  let [decimalPart, fractionPart] = amount.toString().split(".")
  let formattedDecimalPart = ``
  while (decimalPart.length >= 4) {
    formattedDecimalPart = `,${decimalPart.substr(
      decimalPart.length - 3,
      3,
    )}${formattedDecimalPart}`
    decimalPart = decimalPart.substring(0, decimalPart.length - 3)
  }
  formattedDecimalPart = decimalPart + formattedDecimalPart
  if (fractionPart) {
    return `${formattedDecimalPart}.${fractionPart}`
  }
  return formattedDecimalPart
}

interface IProps {
  amount: number
  logo: string
  symbol: string
}

const PaymentHeader = ({ amount, logo, symbol }: IProps) => {
  const { getTranslation } = useContext(LanguageContext)
  const paymentLogo = logo ? <img className={styles.logo} src={logo} alt="logo" /> : <div />
  return (
    <div className={styles.header}>
      {paymentLogo}
      <div className={styles.amount}>
        <span className={styles.number} data-test-id="amount">
          {symbol} {formatAmount(amount)}
        </span>
        <span className={styles.label} data-test-id="amount-title">
          {getTranslation("depositAmountText")}
        </span>
      </div>
    </div>
  )
}

export default PaymentHeader

/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
import { useEffect } from "react"

import usePlausible from "@/common/hooks/usePlausible"

import { PlausibleEvent } from "@/common/enums"
import { ITransactionInfo } from "@/common/types"
import Footer from "@/components/Footer"
import Header from "@/components/Header"
import PaymentForm from "@/components/PaymentForm"
import PaymentHeader from "@/components/PaymentHeader"

import styles from "./styles.module.scss"

const PaymentPage = ({
  logo,
  amount,
  currency_code,
  symbol,
  sections,
  redirect_url,
  transaction_search_frequency,
  transaction_search_timer_for_user,
  flow_type,
  payment_url,
}: ITransactionInfo) => {
  const setEvent = usePlausible()

  useEffect(() => {
    setEvent(PlausibleEvent.OpenPaymentPage)
  }, [])

  useEffect(() => {
    if (flow_type === "link") {
      window.location.href = payment_url
    }
  }, [flow_type])

  return (
    <>
      <Header redirectReturnUrl={redirect_url.return} />
      <div className={styles.paymentPage}>
        <PaymentHeader amount={amount} symbol={symbol} logo={logo} />
        <PaymentForm
          sections={sections}
          redirectReturnUrl={redirect_url.return}
          redirectSuccessUrl={redirect_url.success}
          transactionSearchFrequency={transaction_search_frequency}
          transactionSearchTimerForUser={transaction_search_timer_for_user}
          symbol={symbol}
          amount={amount}
          currencyCode={currency_code}
          flow_type={flow_type}
        />
      </div>
      <Footer />
    </>
  )
}

export default PaymentPage

import { useContext } from "react"
import { ClockIcon } from "../Icons"

import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

interface IProps {
  time: string
}

export const Timer = ({ time }: IProps) => {
  const { getTranslation } = useContext(LanguageContext)
  return (
    <div className={styles.timer}>
      <div className={styles.icon}>
        <ClockIcon />
      </div>
      <div className={styles.info}>
        <div className={styles.label}>{getTranslation("statusCheckText")}</div>
        <div className={styles.time}>{time}</div>
      </div>
    </div>
  )
}

export default Timer

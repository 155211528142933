import { useCallback, useContext, useEffect } from "react"

import usePlausible from "@/common/hooks/usePlausible"

import { PlausibleEvent } from "@/common/enums"

import Footer from "@/components/Footer"
import { SuccessIcon } from "@/components/atoms/Icons"

import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

interface IProps {
  redirectSuccessUrl?: string
}
const SuccessPage = ({ redirectSuccessUrl }: IProps) => {
  const setEvent = usePlausible()

  const { getTranslation } = useContext(LanguageContext)

  useEffect(() => {
    setEvent(PlausibleEvent.OpenSuccessPage)
  }, [])

  const toSuccessUrl = useCallback(() => {
    if (redirectSuccessUrl) {
      window.location.href = redirectSuccessUrl
    }
  }, [redirectSuccessUrl])

  return (
    <>
      <div className={styles.successPage} data-test-id="success-page">
        <div className={styles.icon}>
          <SuccessIcon />
        </div>
        <div className={styles.text} data-test-id="success-page-text">{getTranslation("paymentIsSuccessfullyText")}</div>
        {redirectSuccessUrl && (
          <button 
            className={styles.button} 
            onClick={() => toSuccessUrl()}
            data-test-id="confirm-button"
            >
            {getTranslation("confirmButton")}
          </button>
        )}
      </div>
      <Footer />
    </>
  )
}

export default SuccessPage


import styles from "./styles.module.scss"

const LoaderPage = () => {
  return (
    <div className={styles.loaderPage}>
      <div className={styles.block} style="margin-bottom: 43px">
        <div style="margin-bottom: 32px">
          <div className={styles.animatedBackground} style="width: 56px" />
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 39px">
          <div className={styles.animatedBackground} style="height: 38px; width: 33%;" />
          <div className={styles.animatedBackground} style="height: 38px; width: 33%;" />
        </div>
        <div style="display: flex; justify-content: center">
          <div className={styles.animatedBackground} style="width: 33%;" />
        </div>
      </div>
      <div className={styles.block}>
        <div style="margin-bottom: 33px">
          <div className={styles.animatedBackground} style="100%" />
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 22px">
          <div className={styles.animatedBackground} style="width: 30%" />
          <div className={styles.animatedBackground} style="width: 30%" />
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 22px">
          <div className={styles.animatedBackground} style="width: 20%" />
          <div className={styles.animatedBackground} style="width: 20%" />
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 50px">
          <div className={styles.animatedBackground} style="width: 15%" />
          <div className={styles.animatedBackground} style="width: 30%" />
        </div>
      </div>
      <div className={styles.block}>
        <div style="margin-bottom: 25px">
          <div className={styles.animatedBackground} style="100%" />
        </div>
        <div style="display: flex; justify-content: space-between; margin-bottom: 44px">
          <div className={styles.animatedBackground} style="width: 40%" />
          <div className={styles.animatedBackground} style="width: 40%" />
        </div>
        <div style="display: flex; justify-content: center; margin-bottom: 56px">
          <div className={styles.animatedBackground} style="width: 50%" />
        </div>
        <div style="display: flex; justify-content: center">
          <div className={styles.animatedBackground} style="width: 50%; height: 38px" />
        </div>
      </div>
    </div>
  )
}

export default LoaderPage

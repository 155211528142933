import classNames from "classnames"
import React, { useState } from "react"
import { TipIcon } from "../Icons"
import SimpleModal from "../SimpleModal"

import usePlausible from "@/common/hooks/usePlausible"
import useScreenSize, { ScreenSize } from "@/common/hooks/useScreenSize"

import { PlausibleEvent } from "@/common/enums"

import styles from "./styles.module.scss"

export interface TextInputProps {
  className?: string
  placeholder?: string
  name?: string
  type?: "text"
  value?: string
  label?: string
  tooltip?: string
  error?: any
  onChange: JSX.GenericEventHandler<HTMLInputElement>
  onBlur?: JSX.GenericEventHandler<HTMLInputElement>
  input?: any
}

export const TextInput = ({ className, label, tooltip, error, input: InputComponent, ...rest }: TextInputProps) => {
  const setEvent = usePlausible()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const isMobile = useScreenSize() === ScreenSize.MOBILE

  const onTouchOpenTooltip = (e: TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setShowTooltip(true)
    setEvent(PlausibleEvent.ClickToHelpButton, { name: rest?.name })
  }

  const onOpenTooltip = (e: MouseEvent) => {
    if (!isMobile) {
      e.preventDefault()
      e.stopPropagation()
      setShowTooltip(true)
      setEvent(PlausibleEvent.ClickToHelpButton, { name: rest?.name })
    }
  }

  const leaveHandler = (e: MouseEvent) => {
    if (!isMobile) {
      setShowTooltip(false)
    }
  }

  const closeHandler = (e: MouseEvent) => {
    setShowTooltip(false)
  }

  const classes = classNames(styles.input, { [styles.invalid]: error }, className)

  const renderedInput = InputComponent ? <InputComponent classes={classes} {...rest} /> : (<input
    id="input"
    className={classes}
    {...rest}
  />)

  return (
    <>
      <div style={{ minHeight: "56px" }} className={styles.textInput}>
        <label className={styles.label} htmlFor="input">
          {label}
        </label>
        <div className={styles.container}>
          <div className={styles.block}>
            <div className={styles.wrapper} id="input-wrapper">
              {renderedInput}
              {tooltip && (
                <>
                  <button
                    className={styles.tip}
                    id="input-tip"
                    onMouseEnter={onOpenTooltip}
                    onTouchEnd={onTouchOpenTooltip}
                    onMouseLeave={leaveHandler}
                  >
                    <TipIcon />
                  </button>
                  {!isMobile && showTooltip && (
                    <div className={styles.tooltip}>
                      <img src={tooltip} alt="Tip" />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div style={{ minHeight: "14px" }} className={styles.error}>
            {error}
          </div>
        </div>
      </div>
      <SimpleModal isOpen={isMobile && showTooltip} onClose={closeHandler}>
        <img className={styles.tooltipImg} src={tooltip} alt="Tip" />
      </SimpleModal>
    </>
  )
}

export default TextInput

import React, { useState } from "react"
import classNames from "classnames"
import styles from "./styles.module.scss"

export interface AmountInputProps {
  className?: string
  placeholder?: string
  name?: string
  value?: string
  label?: string
  symbol?: string
  error?: any
  onChange: JSX.GenericEventHandler<HTMLInputElement>
  onBlur?: JSX.GenericEventHandler<HTMLInputElement>
}

export const AmountInput = ({ className, label, error, symbol, ...rest }: AmountInputProps) => {
  return (
    <div style={{ minHeight: "56px" }} className={styles.textInput}>
      <label className={styles.label} htmlFor="input">
        {label}
      </label>
      <div className={styles.container}>
        <div className={styles.block}>
          <div className={styles.wrapper}>
          {symbol && (
            <div className={styles.symbol}>
              {symbol}
            </div>
          )}
            <input
              id="input"
              type="number"
              className={classNames(styles.input, { [styles.invalid]: error }, className)}
              {...rest}
            />
          </div>
        </div>
        <div style={{ height: "14px" }} className={styles.error}>
          {error}
        </div>
      </div>
    </div>
  )
}

export default AmountInput

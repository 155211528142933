import classNames from "classnames"

import styles from "./styles.module.scss"

interface SimpleModalProps {
  isOpen: boolean
  onClose: (e: MouseEvent) => void
  children: JSX.Element
  contentClassName?: string
  closeBtnClassName?: string
}

const SimpleModal = ({
  isOpen,
  onClose,
  children,
  contentClassName,
  closeBtnClassName,
}: SimpleModalProps) => {
  const closeHandler = (event: JSX.TargetedMouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement

    if (target.className === styles.modalBackdrop) {
      onClose(event)
    }
  }

  if (isOpen) {
    return (
      <div className={styles.modalBackdrop} onClick={closeHandler}>
        <div className={styles.modal}>
          <div className={classNames(styles.modalContent, contentClassName)}>{children}</div>
          <button className={classNames(styles.closeButton, closeBtnClassName)} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    )
  }

  return null
}

export default SimpleModal

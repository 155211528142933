export enum PaymentMethods {
  PAYTM = "paytm",
  UPI_PG = "upi_pg",
  UPI_L = "upi_l",
  PHONE_PE = "phone_pe",
}

export enum OrderStatusCode {
  Active = 40400,
  Completed = 40401,
  Expired = 40402,
  NotFound = 40403,
  PaymentMethodNotAvailable = 40000,
  SystemFail = 40700,
  SystemFail2 = 40701,
  Proceed = 40404,
  SystemFail3 = 40405,
}

export enum TransactionInfo {
  TransactionNotFound = 40603,
  Stockpiling = 40602,
  TransactionFound = 40600,
  AwaitingAccrual = 40601,
  /**
   * Это для удаления шаблона. Отдаем, если шаблон с признаком "базовый"
   * Trying to delete a template with the "Default Template" property
   */
  DefaultTemplate = 40605,
  /** от пейкассмы пришел fail */
  PossibleTransactionAlreadyExists = 40606,
  /** от пейкассмы пришел fail */
  PossibleTransactionsDisabledForPS = 40607,
  /** от пейкасссмы пришел fail, превышен лимит
   * For the label on selected payment system has reached the limit the numbers of possible transactions.
   */
  ReachedTheLimitNumbersOfPossibleTransactions = 40608,
  /** от пейкасссмы пришел fail
   * любая другая не установленная ошибка
   */
  AnyReasonError = 40609,
  /** какая то ошибка на стороне PP
   * An error occurred while creating the possible transaction
   */
  CreatingPossibleTransaction = 40610,
}

export enum ComponentType {
  PAYMENT_GUIDE = "paymentGuide",
  AMOUNT = "amountField",
  TEXT = "text",
  TEXT_FIELD = "textField",
  QR = "UPIQRCode",
  CONFIRM_P2P = "buttonP2PConfirm",
  REDIRECT_BTN = "redirectPSButton",
  REDIRECT_UPI_BTN = "redirectUPIAppButton",
  BACK_BTN = "backSectionButton",
  NEXT_BTN = "nextSectionButton",
  CARD_NUMBER = "cardNumberField"
}

export enum PlausibleEvent {
  OpenPaymentPage = `openPaymentPage`,
  OpenInstructions = `openInstructions`,
  CloseInstructions = `closeInstructions`,
  ClickToBackButton = `clickToBackButton`,
  ClickToCopyButton = `clickToCopyButton`,
  ClickToHelpButton = `clickToHelpButton`,
  ClickToConfirmButton = `clickToConfirmButton`,
  OpenSuccessPage = `openSuccessPage`,
  OpenAccumulatedPage = `openAccumulatedPage`,
  OpenCreditedPage = `openCreditedPage`,
  OpenErrorPage = `openErrorPage`,
  ClickToPayNowButton = `clickToPayNowButton`,
  ClickToOpenUpiAppButton = `clickToOpenUpiAppButton`,
  OpenQR = `openQR`,
  CloseQR = `closeQR`,
}

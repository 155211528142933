import React, { useEffect, useState } from "react"
import { API } from "../api"

export const LanguageContext = React.createContext<LanguageContextType>({
  lang: "",
  setLang: () => {},
  getTranslation: () => "",
  loading: false,
})

const LanguageContextProvider = ({ children }: any) => {
  const [lang, setLang] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [json, setJson] = useState<Record<string, string> | null>(null)

  useEffect(() => {
    setLoading(true)
    if (lang) {
        API.getTranslations(lang)
            .then((resp) => {
                setJson(resp.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }
  }, [lang])

  const getTranslation = (key: string) => json ? json[key] : ""

  return (
    <LanguageContext.Provider value={{ 
        lang, 
        setLang, 
        getTranslation, 
        loading 
    }}>
        {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContextProvider

export type LanguageContextType = {
  lang: string,
  setLang: (lang: string) => void,
  getTranslation: (key: string) => string,
  loading: boolean,
}
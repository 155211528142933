import { useCallback, useContext, useEffect } from "react"

import usePlausible from "@/common/hooks/usePlausible"

import { PlausibleEvent } from "@/common/enums"

import Footer from "@/components/Footer"
import { CreditedIcon } from "../../components/atoms/Icons"

import styles from "./styles.module.scss"
import { LanguageContext } from "@/common/contexts/langState"

interface IProps {
  redirectReturnUrl?: string
}
const CreditedPage = ({ redirectReturnUrl }: IProps) => {
  const setEvent = usePlausible()
  const { getTranslation } = useContext(LanguageContext)
  const toReturnUrl = useCallback(() => {
    if (redirectReturnUrl) {
      setEvent(PlausibleEvent.ClickToBackButton)
      window.location.href = redirectReturnUrl
    }
  }, [redirectReturnUrl])

  useEffect(() => {
    setEvent(PlausibleEvent.OpenCreditedPage)
  }, [])

  return (
    <>
      <div className={styles.creditedPage} data-test-id="credited-page">
        <div className={styles.icon}>
          <CreditedIcon />
        </div>
        <div className={styles.title} data-test-id="credited-page-title">
          {getTranslation("waitingHeader")}
        </div>
        <div className={styles.text} data-test-id="credited-page-text">
          {getTranslation("waitFormHint")}
        </div>
        {redirectReturnUrl && (
          <button
            className={styles.button}
            onClick={() => toReturnUrl()}
            data-test-id="back-button"
          >
            {getTranslation("backButton")}
          </button>
        )}
      </div>
      <Footer />
    </>
  )
}

export default CreditedPage
